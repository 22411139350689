import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Login from "../components/auth/Login"

const FreshdeskLoginPage = () => {
  return (
    <Layout hasHero={false}>
      <SEO title="Support login" />
      <Login freshdesk={true} />
    </Layout>
  )
}

export default FreshdeskLoginPage
